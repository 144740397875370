<script setup lang="ts">

</script>

<template>
    <div class="container flex justify-between mb-4 px-0">
        <slot/>
    </div>
</template>

<style scoped lang="scss">

</style>
