<script setup lang="ts">
</script>

<template>
    <h1 class="text-2xl mt-8">404 {{ $t('Page not found!') }}</h1>
</template>

<style scoped lang="scss">

</style>
