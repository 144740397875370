<script setup lang="ts">
import {computed, provide} from "vue";

const {size} = defineProps<{
    size: 'small' | 'normal',
}>();

provide('size', size);

const boxClasses = computed(() => [
    'tabs',
    'flex',
    'items-center',
    size === 'small' ? 'gap-18px' : 'gap-6',
]);

</script>

<template>
    <div :class="boxClasses">
        <slot/>
    </div>
</template>

<style scoped lang="scss">

</style>
